<template>
    <div class="inner_pages">
        <!-- <InnerSidebar/> -->
        <div class="loader_area" v-if="pageDetailLoader"><quote-loader/></div>
        <div class="respective_content" v-else>
            <div class="section_header">
                <h2>Page</h2>
                <ul>
                    <li class="mr-2">
                        <label for="preview_page" class="switch_option capsule_btn">
                            <h5 class="mr-3">Preview Page</h5>
                            <input type="checkbox" id="preview_page" v-model="previewPage" hidden>
                            <div><span></span></div>
                        </label>
                    </li>
                    <li><button type="button" class="pointer" @click="globalSetting = true"><i class="fas fa-cog"></i></button></li>
                </ul>
            </div>
            <div class="client_card">
                <div class="card_header pointer" :style="`background: ${selectedPage.header_bgcolor}; color: ${selectedPage.header_textcolor};`" @click="headerSetting = true">
                    <nav>
                        <img class="logo_img" :src="selectedPage.logo && selectedPage.logo != null ? selectedPage.logo : require('@/assets/images/thumb/default-logo.svg')" alt="logo">
                        <h4>Dashboard</h4>
                    </nav>
                    <div class="user_box">
                        <img :src="(selectedPage.author && selectedPage.author != null)  ? selectedPage.author : user.profile_pic" alt="chris">
                        <div class="user_info">
                            <h5 :style="`color: ${selectedPage.header_textcolor};`">{{ user.full_name }}</h5>
                        </div>
                    </div>
                    <button class="header_setting"><i class="fas fa-cog"></i></button>
                </div>
                <div class="card_body cvr_image">
                    <div @click="coverSetting = true" class="pointer w-100" :class="{ 'draft' : !form.display_cover , 'cover-image' : form.cover_type == 1}">
                        <img :src="form.cover ? form.cover : require('@/assets/images/thumb/default-cover.svg')" alt="banner" class="banner_img" v-if="(form.cover_type == 2 || form.cover_type == 3)">
                        <img :src="form.cover ? form.cover : require('@/assets/images/thumb/default-sm-cover.svg')" alt="banner" class="banner_img tab" v-if="(form.cover_type == 2 || form.cover_type == 3)">
                        <img :src="form.cover ? form.cover : require('@/assets/images/thumb/default-cover-3-2.svg')" alt="banner" class="banner_img cell" v-if="(form.cover_type == 2 || form.cover_type == 3)">
                        <span v-if="form.cover_type == 1" class="overlay" :style="`background-color: ${form.cover_background_color ? form.cover_background_color : form.opac_overlay_color};`"></span>
                        <span v-else-if="form.cover_type == 2 && form.has_cover_overlay" class="overlay" :style="`background-color: ${form.overlay_color}; opacity: ${(form.opac_overlay_color == 'transparent' || !form.has_cover_overlay || form.overlay_opacity == 0) ? '0': ((form.overlay_opacity / 100 ))};`"></span>
                        <span v-else-if="form.cover_type == 3 && form.has_cover_overlay" class="overlay" :style="`background-color: ${form.overlay_color}; opacity: ${(form.opac_overlay_color == 'transparent' || !form.has_cover_overlay || form.overlay_opacity == 0) ? '0': ((form.overlay_opacity / 100 ))};`"></span>
                        <div class="banner_content">
                            <h4 class="shadow-none" v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${form.headline_setting.font_size}px; line-height: ${(parseInt(form.headline_setting.font_size) + 20)}px; font-weight: ${form.headline_setting.font_weight}; font-family: ${form.headline_setting.font_family}; color: ${form.cover_textcolor}; ${form.headline_setting.has_shadow ? 'text-shadow: ' + form.headline_setting.shadow_x +'px ' + form.headline_setting.shadow_y + 'px ' + form.headline_setting.shadow_blur + 'px ' + form.headline_setting.shadow_color : 'none'}`">{{ form.headline }}</h4>
                            <h1 class="shadow-none" v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${form.subheadline_setting.font_size}px; line-height: ${(parseInt(form.subheadline_setting.font_size) + 20)}px; font-weight: ${form.subheadline_setting.font_weight}; font-family: ${form.subheadline_setting.font_family}; color: ${form.subheadline_setting.font_color}; ${form.subheadline_setting.has_shadow ? 'text-shadow: ' + form.subheadline_setting.shadow_x +'px ' + form.subheadline_setting.shadow_y + 'px ' + form.subheadline_setting.shadow_blur + 'px ' + form.subheadline_setting.shadow_color : 'none'}`">{{ form.subheadline }}</h1>
                        </div>
                    </div>
                    <image-library v-model="coverImage" :is-button="true" image-type="public-cover" upload-text="Cover" />
                    <div class="action">
                        <label for="active_banner" class="switch_option capsule_btn">
                            <h5 class="mr-2">{{ form.display_cover ? 'Active' : 'Inactive' }}</h5>
                            <input type="checkbox" id="active_banner" :true-value="1" :false-value="0" v-model="form.display_cover" @change="updatePageSetting({ display_cover: form.display_cover })" hidden>
                            <div><span></span></div>
                        </label>
                        <i class="fas fa-cog" @click="coverSetting = true"></i>
                    </div>
                </div>
            </div>
            <div class="dashboard_content">
                <div class="left_side">
                    <div class="section_item dotted_item mb-5" :class="{ 'draft' : !form.display_media_headline }">
                        <h3 class="m-0" :class="`text_align_${selectedPage.media_headline_position}`">
                            {{ selectedPage.media_headline ? selectedPage.media_headline : 'Media Headline'}}
                        </h3>
                        <div class="step_action">
                            <label for="show_media_headline" class="switch_option capsule_btn border-0 p-0 ml-auto">
                                <input type="checkbox" id="show_media_headline" :true-value="1" :false-value="0" v-model="form.display_media_headline" @change="updatePageSetting({ display_media_headline: form.display_media_headline })" hidden>
                                <div><span></span></div>
                            </label>
                            <button type="button" class="edit" @click="editMediaHeadline = true"><i class="fas fa-cog"></i></button>
                        </div>
                    </div>
                    <div class="section_item video_wpr p-0 mb-5" :class="{ draft: !form.display_media }">
                        <div class="step_action">
                            <label for="show_form_video" class="switch_option capsule_btn border-0 p-0 ml-auto">
                                <input type="checkbox" id="show_form_video" :true-value="1" :false-value="0" v-model="form.display_media" @change="updatePageSetting({ display_media: form.display_media })" hidden />
                                <div><span></span></div>
                            </label>
                            <button type="button" class="edit pointer" @click="mediaSetting = true"><i class="fas fa-cog"></i></button>
                        </div>
                        <div class="video_wpr" v-if="(form.media.option == 1 && form.media.embed) || (form.media.option == 2 && form.media.url)">
                            <div v-if="form.media.option == 2" v-html="parseEmbedCode(form.media.url)"></div>
                            <div v-else v-html="form.media.embed"></div>
                        </div>
                        <img v-else :src="form.media.image ? form.media.image : require('@/assets/images/thumb/video-placeholder.svg')" />
                    </div>
                    <div class="section_item dotted_item" :class="{'draft' : !form.display_content_headline}">
                        <h3 class="m-0 pt-4" :class="`text_align_${selectedPage.content_headline_position}`">
                            {{ selectedPage.content_headline ? selectedPage.content_headline : 'Content Headline' }}
                        </h3>
                        <div class="step_action">
                            <label for="show_content_headline" class="switch_option capsule_btn border-0 p-0 ml-auto">
                                <input type="checkbox" id="show_content_headline" :true-value="1" :false-value="0" v-model="form.display_content_headline" @change="updatePageSetting({ display_content_headline: form.display_content_headline })" hidden>
                                <div><span></span></div>
                            </label>
                            <button type="button" class="edit" @click="editContentHeadline = true"><i class="fas fa-cog"></i></button>
                        </div>
                    </div>
                    <div class="section_item dotted_item mt-5" :class="{'draft' : !form.display_content}">
                        <div class="step_action">
                            <label for="show_page_content" class="switch_option capsule_btn border-0 p-0 ml-auto">
                                <input type="checkbox" id="show_page_content" :true-value="1" :false-value="0" v-model="form.display_content" @change="updatePageSetting({ display_content: form.display_content })" hidden>
                                <div><span></span></div>
                            </label>
                            <button type="button" class="edit" @click="contentSetting = true"><i class="fas fa-cog"></i></button>
                        </div>
                        <p class="redactor-styles redactor-in para pt-4" v-html="selectedPage.content ? selectedPage.content : 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'"></p>
                    </div>
                    <div class="section_item mt-5 coach_card">
                        <h3>Actions</h3>
                        <div class="step_action step_top">
                            <label for="show_cta_btn" class="switch_option capsule_btn border-0 p-0 ml-auto">
                                <input type="checkbox" id="show_cta_btn" true-value="display" false-value="hide" v-model="form.cta_button.display" @change="updatePageSetting({ cta_button: form.cta_button })" hidden>
                                <div><span></span></div>
                            </label>
                            <button type="button" class="edit" @click="userActionSetting = true"><i class="fas fa-cog"></i></button>
                        </div>
                        <ul class="button_list">
                            <li :class="{ 'draft' : form.cta_button.display == 'hide' }">
                                <button class="text-center" type="button" :class="`button_${selectedPage.cta_button && selectedPage.cta_button.position ? selectedPage.cta_button.position : '' }`"  :style="`background-color: ${selectedPage.cta_button ? selectedPage.cta_button.btn_color : ''}; color: ${selectedPage.cta_button ? selectedPage.cta_button.btn_text_color : ''};`">{{ selectedPage.cta_button ? selectedPage.cta_button.text : 'Click Here' }}</button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="right_side">
                    <!-- <div class="section_item dotted_item mb-5" :class="{'draft' : !form.display_offer_headline}">
                        <h3 class="m-0" :class="`text_align_${selectedPage.offer_headline_position}`">
                            {{ selectedPage.offer_headline ? selectedPage.offer_headline : 'Offer Headline' }}
                        </h3>
                        <div class="step_action">
                            <label for="offer_headline" class="switch_option capsule_btn border-0 p-0 ml-auto">
                                <input type="checkbox" id="offer_headline" :true-value="1" :false-value="0" v-model="form.display_offer_headline" @change="updatePageSetting({ display_offer_headline: form.display_offer_headline })" hidden>
                                <div><span></span></div>
                            </label>
                            <button type="button" class="edit" @click="offerHeadlineSetting = true"><i class="fas fa-cog"></i></button>
                        </div>
                    </div> -->
                    <div class="upload_image mb-5" :class="{'draft' : !form.display_page_offer}">
                        <h3 class="m-0" :class="`text_align_${selectedPage.offer_headline_position}`">
                            {{ selectedPage.offer_headline ? selectedPage.offer_headline : 'Offer Headline' }}
                        </h3>
                        <div class="step_action">
                            <label for="offer" class="switch_option capsule_btn border-0 p-0">
                                <input type="checkbox" id="offer" :true-value="1" :false-value="0" v-model="form.display_page_offer" @change="updatePageSetting({ display_page_offer: form.display_page_offer })" hidden>
                                <div><span></span></div>
                            </label>
                            <button type="button" class="edit" @click="offerSetting = true"><i class="fas fa-cog"></i></button>
                        </div>
                        <label class="offer-image-label">
                            <image-library v-if="offerImage" v-model="offerImage" image-type="offer-popup" upload-text="Image" />
                            <img v-else src="@/assets/images/thumb/offer-placeholder.svg" alt=""/>
                            <button type="button" v-show="offerImage" class="custom-image-remove-button" @click="removeOfferImage()">Remove</button>
                        </label>
                    </div>
                    <div class="section_item mb-5 coach_card" :class="{ draft: !form.display_coach }">
                        <div class="step_action">
                            <label for="coachcard" class="switch_option capsule_btn border-0 p-0 ml-auto">
                                <input type="checkbox" id="coachcard" :true-value="1" :false-value="0" v-model="form.display_coach" @change="updatePageSetting({ display_coach: form.display_coach })" hidden>
                                <div><span></span></div>
                            </label>
                            <button type="button" class="edit pointer" @click="coachSetting = true"><i class="fas fa-cog"></i></button>
                        </div>
                        <h3>{{ form.coach_headline ? form.coach_headline : 'Coach Headline' }}</h3>
                        <div class="user_box">
                            <img :src="form.coach_image ? form.coach_image : require('@/assets/images/male-female.png')" alt="chris">
                            <div class="user_info">
                                <h3>{{ form.coach_name ? form.coach_name : 'Joe Trainer' }}</h3>
                                <h5>{{ form.coach_title ? form.coach_title : 'PHD, CSCS' }}</h5>
                            </div>
                        </div>
                        <p v-html="form.coach_bio ? form.coach_bio : 'Joe Trainer, your fitness guru, has been shaping better bodies and healthier lifestyles for over two decades. With a PhD in Pumping Hearts and a CSCS (Certified Strong Coffee Sipper), Joe is more than your average coach. When he\'s not helping clients like you reach their fitness goals, he\'s probably creating a new protein shake recipe or lifting large animals just for fun.'"></p>
                    </div>
                    <div class="section_item mb-2 border-item downloads" :class="{'draft' : !form.is_download}">
                        <h3 class="m-0">Downloads</h3>
                        <div class="step_action">
                            <label for="show_download" class="switch_option capsule_btn border-0 p-0 ml-auto">
                                <input type="checkbox" id="show_download" :true-value="1" :false-value="0" v-model="form.is_download" @change="updatePageSetting({ is_download: form.is_download })" hidden>
                                <div><span></span></div>
                            </label>
                            <button type="button" class="edit" @click="downloadSetting = true"><i class="fas fa-cog"></i></button>
                        </div>
                        <ul class="download_list" v-if="downloads.length">
                            <li v-for="(download, d) of downloads" :key="d">
                                <h5 v-if="['xlsx', 'xlsm', 'xls'].includes(download.ext)">
                                    <i class="far fa-file-excel mr-1"></i> <label class="file_name">{{ download.filename }}</label>
                                </h5>
                                <h5 v-else-if="['jpg', 'jpeg', 'png', 'pneg', 'gif', 'tif', 'bmp'].includes(download.ext)">
                                    <i class="far fa-file-image mr-1"></i> <label class="file_name">{{ download.filename }}</label>
                                </h5>
                                <h5 v-else-if="['pdf'].includes(download.ext)">
                                    <i class="far fa-file-pdf mr-1"></i> <label class="file_name">{{ download.filename }}</label>
                                </h5>
                                <h5 v-else-if="['mp4', 'mkv', 'wmv'].includes(download.ext)">
                                    <i class="far fa-file-video mr-1"></i> <label class="file_name">{{ download.filename }}</label>
                                </h5>
                                <h5 v-else-if="['docx', 'doc', 'docm'].includes(download.ext)">
                                    <i class="far fa-file-word mr-1"></i> <label class="file_name">{{ download.filename }}</label>
                                </h5>
                                <h5 v-else-if="['pptx', 'pptm', 'potx', 'potm', 'pot', 'ppsx'].includes(download.ext)">
                                    <i class="far fa-file-powerpoint mr-1"></i> <label class="file_name">{{ download.filename }}</label>
                                </h5>
                                <h5 v-else-if="['mp3', 'pcm', 'wav', 'aac','ogg', 'wma'].includes(download.ext)">
                                    <i class="far fa-file-audio mr-1"></i> <label class="file_name">{{ download.filename }}</label>
                                </h5>
                                <h5 v-else>
                                    <i class="far fa-file mr-1"></i> <label class="file_name">{{ download.filename }}</label>
                                </h5>
                                <span>
                                    <i class="fas fa-cloud-download-alt pointer" @click="handleFileDownload(download)"></i>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <footer class="footer-content">
                <div class="step_action">
                    <label for="footercard" class="switch_option capsule_btn border-0 p-0 ml-auto">
                        <input type="checkbox" id="footercard" :true-value="1" :false-value="0" v-model="form.display_footer" @change="updatePageSetting({ display_footer: form.display_footer })" hidden>
                        <div><span></span></div>
                    </label>
                    <!-- <img src="@/assets/images/cog.png" alt="" class="ml-3 pointer" @click="coachSetup = true"> -->
                    <button type="button" class="edit" @click="footerSetting = true"><i class="fas fa-cog"></i></button>
                </div>
                <div class="dashboard_footer" :class="{'draft' : !form.display_footer}" :style="`background: ${selectedPage.footer_bgcolor}`">
                    <ul>
                        <li class="pointer" v-if="selectedPage.has_facebook"><a :href="selectedPage.footer_facebook" :style="`border-color: ${selectedPage.footer_textcolor}; color: ${selectedPage.footer_textcolor};`"><i class="fab fa-facebook-f"></i></a></li>
                        <li class="pointer" v-if="selectedPage.has_twitter"><a :href="selectedPage.footer_twitter" :style="`border-color: ${selectedPage.footer_textcolor}; color: ${selectedPage.footer_textcolor};`"><i class="fab fa-twitter"></i></a></li>
                        <li class="pointer" v-if="selectedPage.has_instagram"><a :href="selectedPage.footer_instagram" :style="`border-color: ${selectedPage.footer_textcolor}; color: ${selectedPage.footer_textcolor};`"><i class="fab fa-instagram"></i></a></li>
                        <li class="pointer" v-if="selectedPage.has_linkedin"><a :href="selectedPage.footer_linkedin" :style="`border-color: ${selectedPage.footer_textcolor}; color: ${selectedPage.footer_textcolor};`"><i class="fab fa-linkedin"></i></a></li>
                        <li class="pointer" v-if="selectedPage.has_youtube"><a :href="selectedPage.footer_youtube" :style="`border-color: ${selectedPage.footer_textcolor}; color: ${selectedPage.footer_textcolor};`"><i class="fab fa-youtube"></i></a></li>
                    </ul>
                    <h4 :style="`color: ${selectedPage.footer_textcolor};`">{{ selectedPage.footer_company }}</h4>
                    <p>
                        <a :href="selectedPage.footer_terms" :style="`color: ${selectedPage.footer_textcolor};`">Terms</a>
                        <a :href="selectedPage.footer_policy" :style="`color: ${selectedPage.footer_textcolor};`">Privacy</a>
                        <span v-html="selectedPage.white_label"></span>
                    </p>
                </div>
            </footer>
        </div>
        <setting-component v-model="globalSetting" />
        <header-setting v-model="headerSetting" />
        <cover-setting v-model="coverSetting" />
        <media-headline v-model="editMediaHeadline" />
        <media-setting v-model="mediaSetting" />
        <content-headline v-model="editContentHeadline" />
        <content-setting v-model="contentSetting" />
        <action-button-setting v-model="userActionSetting" :toggle-offer-setting="toggleOfferSetting" />
        <offer-headline v-model="offerHeadlineSetting" />
        <offer-setting v-model="offerSetting" />
        <coach-setting v-model="coachSetting" />
        <download-setting v-model="downloadSetting" />
        <footer-setting v-model="footerSetting" />
        <preview-page v-model="previewPage" />
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions, mapGetters } from 'vuex'

    // const InnerSidebar = defineAsyncComponent(() => import('@/components/InnerSidebar'))
    const ImageLibrary = defineAsyncComponent(() => import('@/components/image-library/ImageLibrary'))
    const SettingComponent = defineAsyncComponent(() => import('@/pages/page/components/Setting'))
    const HeaderSetting = defineAsyncComponent(() => import('@/pages/page/components/HeaderSetting'))
    const CoverSetting = defineAsyncComponent(() => import('@/pages/page/components/CoverSetting'))
    const MediaHeadline = defineAsyncComponent(() => import('@/pages/page/components/MediaHeadline'))
    const MediaSetting = defineAsyncComponent(() => import('@/pages/page/components/MediaSetting'))
    const ContentHeadline = defineAsyncComponent(() => import('@/pages/page/components/ContentHeadline'))
    const ContentSetting = defineAsyncComponent(() => import('@/pages/page/components/ContentSetting'))
    const ActionButtonSetting = defineAsyncComponent(() => import('@/pages/page/components/ActionButtonSetting'))
    const OfferHeadline = defineAsyncComponent(() => import('@/pages/page/components/OfferHeadline'))
    const OfferSetting = defineAsyncComponent(() => import('@/pages/page/components/OfferSetting'))
    const CoachSetting = defineAsyncComponent(() => import('@/pages/page/components/CoachSetting'))
    const DownloadSetting = defineAsyncComponent(() => import('@/pages/page/components/DownloadSetting'))
    const FooterSetting = defineAsyncComponent(() => import('@/pages/page/components/FooterSetting'))
    const PreviewPage = defineAsyncComponent(() => import('@/pages/page/components/PreviewPage'))

    import VideoParser from '@/utils/VideoParser'
    import Helper from '@/utils/Helper'

    export default {
        name: 'Page Edit',

        data () {
            return {
                coverImage: '',
                offerImage: '',
                form: {
                    headline_setting: {},
                    subheadline_setting: {},
                    display_media_headline: 0,
                    cover: '',
                    cover_textcolor: '',
                    cover_type: '',
                    cover_background_color: '',
                    overlay_color: '',
                    overlay_opacity: 0,
                    has_cover_overlay: 0,
                    opac_overlay_color: '',
                    display_media: 0,
                    media: {},
                    display_content_headline: 0,
                    display_content: 0,
                    cta_button: { display: 'hide' },
                    display_footer: 0,
                },
                mounted: false,
                editMediaHeadline: false,
                headerSetting: false,
                coverSetting: false,
                previewPage: false,
                mediaSetting: false,
                editContentHeadline: false,
                contentSetting: false,
                userActionSetting: false,
                offerHeadlineSetting: false,
                offerSetting: false,
                coachSetting: false,
                forcedRemoveImage: false,
                downloadSetting: false,
                globalSetting: false,
                footerSetting: false,
            };
        },

        components: {
            // InnerSidebar,
            ImageLibrary,
            SettingComponent,
            HeaderSetting,
            CoverSetting,
            MediaHeadline,
            MediaSetting,
            ContentHeadline,
            PreviewPage,
            ContentSetting,
            ActionButtonSetting,
            OfferHeadline,
            OfferSetting,
            CoachSetting,
            DownloadSetting,
            FooterSetting,
        },

        watch: {
            '$route.params' (params) {
                const vm = this;

                if (params.page) {
                    vm.getPageDetails(params.page);
                    vm.getPageDownloads(params.page);
                }
            },

            selectedPage (page) {
                const vm = this;

                vm.resetPage();
            },

            coverImage (cover) {
                const vm = this;

                vm.updatePage({ cover, page_id: vm.selectedPage.id });
            },

            offerImage (offer_image, image) {
                const vm = this;

                if (vm.forcedRemoveImage) {
                    vm.forcedRemoveImage = false;
                } else {
                    if (vm.mounted && !vm.pageDetailLoader && offer_image) {
                        vm.updatePage({ offer_image, page_id: vm.selectedPage.id });
                    }

                    if (!offer_image && image) {
                        vm.offerImage = image;
                    }
                }
            },

            globalSetting (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            previewPage (val) {
                const vm = this;

                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }

                vm.toggleGistChatBox();
            },

            editMediaHeadline (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            headerSetting (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            coverSetting (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            mediaSetting (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            editContentHeadline (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            contentSetting (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            userActionSetting (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            offerHeadlineSetting (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            offerSetting (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            downloadSetting (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            footerSetting (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            selectedPage: state => state.pageModule.selectedPage,
            pageDetailLoader: state => state.pageModule.pageDetailLoader,
            pageUpdateLoader: state => state.pageModule.pageUpdateLoader,
            downloads: state => state.pageModule.downloads,
            pageDefaultContent: state => state.commonModule.pageDefaultContent,
            tagsGroup: state => state.tagModule.tagsGroup,
        }),

        mounted () {
            const vm = this;

            if (vm.$route.params && vm.$route.params.page) {
                vm.getPageDetails(vm.$route.params.page);
                vm.getPageDownloads(vm.$route.params.page);
            }

            if (!Object.values(vm.pageDefaultContent).length) {
                vm.getDefaultContentByType(2);
            }

            if (vm.tagsGroup.length == 0) {
                vm.getTags();
            }

        },

        unmounted () {
            const vm = this;

            vm.resetToDefaultState();
        },

        methods: {
            ...mapActions({
                getPageDetails: 'pageModule/getPageDetails',
                updatePage: 'pageModule/updatePage',
                getPageDownloads: 'pageModule/getPageDownloads',
                getDefaultContentByType: 'commonModule/getDefaultContentByType',
                getTags: 'tagModule/getTags',
            }),

            resetPage () {
                const vm = this;

                let headline_setting = vm.selectedPage.headline_setting ? JSON.parse(JSON.stringify(vm.selectedPage.headline_setting)) : {};
                let subheadline_setting = vm.selectedPage.subheadline_setting ? JSON.parse(JSON.stringify(vm.selectedPage.subheadline_setting)) : {};

                if (!headline_setting.font_size) {
                    headline_setting = {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 50,
                        font_color: '#2C3E50',
                        has_shadow: headline_setting.has_shadow ? headline_setting.has_shadow :  0,
                        shadow_x: headline_setting.shadow_x ? headline_setting.shadow_x : 0,
                        shadow_y: headline_setting.shadow_y ? headline_setting.shadow_y : 4,
                        shadow_blur: headline_setting.shadow_blur ? headline_setting.shadow_blur : 4,
                        shadow_color: headline_setting.shadow_color ? headline_setting.shadow_color : '#2F7FED',
                    };
                }

                if (!subheadline_setting.font_size) {
                    subheadline_setting = {
                        font_family: 'Inter',
                        font_weight: 500,
                        font_size: 25,
                        font_color: '#2C3E50',
                        has_shadow: subheadline_setting.has_shadow ? subheadline_setting.has_shadow :  0,
                        shadow_x: subheadline_setting.shadow_x ? subheadline_setting.shadow_x : 0,
                        shadow_y: subheadline_setting.shadow_y ? subheadline_setting.shadow_y : 4,
                        shadow_blur: subheadline_setting.shadow_blur ? subheadline_setting.shadow_blur : 4,
                        shadow_color: subheadline_setting.shadow_color ? subheadline_setting.shadow_color : '#2F7FED',
                    };
                }

                vm.form = {
                    headline_setting,
                    subheadline_setting,
                    display_media_headline: vm.selectedPage.display_media_headline ? vm.selectedPage.display_media_headline : 0,
                    display_cover: vm.selectedPage.display_cover ? vm.selectedPage.display_cover : 0,
                    cover: vm.selectedPage.cover ? vm.selectedPage.cover : '',
                    headline: vm.selectedPage.headline ? vm.selectedPage.headline : '',
                    subheadline: vm.selectedPage.subheadline ? vm.selectedPage.subheadline : '',
                    cover_textcolor: vm.selectedPage.cover_textcolor ? vm.selectedPage.cover_textcolor : '',
                    cover_type: vm.selectedPage.cover_type ? vm.selectedPage.cover_type : 2,
                    cover_background_color: vm.selectedPage.cover_background_color ? vm.selectedPage.cover_background_color : '',
                    overlay_color: vm.selectedPage.overlay_color ? vm.selectedPage.overlay_color : '',
                    overlay_opacity: vm.selectedPage.overlay_opacity ? vm.selectedPage.overlay_opacity : 0,
                    has_cover_overlay: vm.selectedPage.has_cover_overlay ? vm.selectedPage.has_cover_overlay : 0,
                    opac_overlay_color: vm.selectedPage.opac_overlay_color ? vm.selectedPage.opac_overlay_color : '',
                    display_media: vm.selectedPage.display_media ? vm.selectedPage.display_media : 0,
                    media: vm.selectedPage.media ? vm.selectedPage.media : {},
                    display_content_headline: vm.selectedPage.display_content_headline ? vm.selectedPage.display_content_headline : 0,
                    display_content: vm.selectedPage.display_content ? vm.selectedPage.display_content : 0,
                    cta_button: vm.selectedPage.cta_button ? JSON.parse(JSON.stringify(vm.selectedPage.cta_button)) : { display: 'hide' },
                    display_offer_headline: vm.selectedPage.display_offer_headline ? vm.selectedPage.display_offer_headline : 0,
                    display_page_offer: vm.selectedPage.display_page_offer ? vm.selectedPage.display_page_offer : 0,
                    display_coach: vm.selectedPage.display_coach ? vm.selectedPage.display_coach : 0,
                    coach_headline: vm.selectedPage.coach_headline ? vm.selectedPage.coach_headline : 'Coach Headline',
                    coach_bio: vm.selectedPage.coach_bio ? vm.selectedPage.coach_bio : '',
                    coach_image: (vm.selectedPage.coach_image &&  vm.selectedPage.coach_image != null) ? vm.selectedPage.coach_image : '',
                    coach_name: vm.selectedPage.coach_name ? vm.selectedPage.coach_name : '',
                    coach_title: vm.selectedPage.coach_title ? vm.selectedPage.coach_title : '',
                    is_download: vm.selectedPage.is_download ? vm.selectedPage.is_download : 0,
                    display_footer: vm.selectedPage.display_footer ? vm.selectedPage.display_footer : 0,
                };

                vm.offerImage = vm.selectedPage.offer_image ? vm.selectedPage.offer_image : '';

                setTimeout(function () {
                    vm.mounted = true;
                }, 2000);

            },

            parseEmbedCode (url) {
                const vm = this;

                return VideoParser.embedCodeFromUrl(url)
            },

            removeOfferImage () {
                const vm = this;

                vm.forcedRemoveImage = true;

                vm.updatePage({ offer_image: '', page_id: vm.selectedPage.id }).then((result) => {
                    if (result) {
                        vm.offerImage = '';
                    }
                });
            },

            handleFileDownload (file) {
                Helper.downloadFile(file.download_link, (file.filename.replace('.'+file.ext, ''))+'.'+file.ext, file.file_type);
            },

            updatePageSetting (params) {
                const vm = this;

                if (vm.mounted) {
                    params.page_id = vm.selectedPage.id;
                    vm.updatePage(params);
                }
            },

            resetToDefaultState () {
                const vm = this;

                const formData = {
                    coverImage: '',
                    offerImage: '',
                    form: {
                        headline_setting: {},
                        subheadline_setting: {},
                        display_media_headline: 0,
                        cover: '',
                        cover_textcolor: '',
                        cover_type: '',
                        cover_background_color: '',
                        overlay_color: '',
                        overlay_opacity: 0,
                        has_cover_overlay: 0,
                        opac_overlay_color: '',
                        display_media: 0,
                        media: {},
                        display_content_headline: 0,
                        display_content: 0,
                        cta_button: { display: 'hide' },
                        display_footer: 0,
                    },
                    mounted: false,
                    editMediaHeadline: false,
                    headerSetting: false,
                    coverSetting: false,
                    previewPage: false,
                    mediaSetting: false,
                    editContentHeadline: false,
                    contentSetting: false,
                    userActionSetting: false,
                    offerHeadlineSetting: false,
                    offerSetting: false,
                    coachSetting: false,
                    forcedRemoveImage: false,
                    downloadSetting: false,
                    globalSetting: false,
                    footerSetting: false,
                };

                Object.assign(vm.$data, formData);
            },

            toggleOfferSetting () {
                const vm = this;

                vm.offerSetting = true;
            },
        },
    }
</script>

<style scoped>
    :deep(.offer-image-label .image-remove-button) {
        display: none;
    }

    .offer-image-label {
        position: relative;
        line-height: 0;
    }

    .custom-image-remove-button {
        padding: 5px 15px;
        font-size: 11px;
        line-height: 13px;
        border-radius: 12px;
        background: #fff;
        color: #121525;
        font-weight: 600;
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 2;
        cursor: pointer;
    }
    .section_header{
        max-width: 1170px;
        width: 100%;
        margin: 0 auto 15px auto;
    }
    .client_card{
        border: 0;
        border-radius: 5px;
        max-width: 1170px;
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
    }
    .dashboard_content .left_side{
        flex: 1 1 60%;
        padding: 10px 20px;
    }
    .dashboard_content .right_side{
        flex: 1 1 28%;
        padding: 10px 20px;
    }
    .footer-content{
        max-width: 1170px;
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
    }
    :deep(.redactor-styles.redactor-in ul){
        padding-left: 20px;
    }

    .modal.faq_modal .modal_container {
        padding: 0;
    }

    .modal.faq_modal .modal_header,
    .modal.faq_modal .modal_body,
    .modal.faq_modal .modal_footer {
        width: 100%;
    }

    .modal.faq_modal .modal_footer {
        border-radius: 0 0 12px 12px;
    }

    .section_item {
        position: relative;
        overflow: hidden;
    }

    .border-item {
        padding: 23px 30px;
        /* border: 1px solid #eaeaea; */
        border-radius: 8px;
        background: #fff;
    }

    .dashboard_content {
        padding: 50px 15px;
    }

    .dashboard_content h3 {
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 15px;
    }

    .dotted_item {
        padding: 20px 30px;
        /* border: 1px solid #e9e9e9; */
        border-radius: 8px;
        background: #fff;
    }

    .dashboard_content .dotted_item h3 {
        align-items: center;
    }

    .status {
        height: 5px;
        border-radius: 3px;
        background: #ddd;
        position: relative;
    }

    .status span {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 3px;
        background: #2f7fed;
    }

    .step_card {
        display: flex;
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        margin-top: 30px;
        overflow: hidden;
        background: #fff;
    }

    .step_card .thumb {
        flex: 0 0 200px;
        border-radius: 8px 0 0 8px;
        position: relative;
    }

    .step_card .thumb>img {
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .step_card .step_info {
        padding: 25px 30px;
        text-decoration: none;
    }

    .step_card .step_info h3 {
        font-size: 20px;
        line-height: 26px;
        color: #121525;
        align-items: center;
    }

    .step_card .step_info h3 .step_status {
        font-size: 14px;
        line-height: 18px;
        margin-right: 10px;
    }

    .step_card .step_info h3 a {
        text-decoration: none;
        color: inherit;
    }

    .edit {
        font-size: 14px;
        margin-left: 7px;
        cursor: pointer;
        color: #9f9e9e;
    }

    .step_card .step_info p {
        font-size: 15px;
        line-height: 22px;
        color: #5a5a5a;
    }

    .step_card .thumb .replace_img {
        position: absolute;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 15px;
        top: 12px;
    }

    .step_card .thumb .replace_img img {
        max-width: 12px;
        height: auto;
    }

    .step_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        border-radius: 6px;
        background: #2F7FED;
        color: #fff;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 23px;
        font-weight: 500;
        padding: 0 20px;
        border: 0;
        text-transform: uppercase;
    }

    .step_btn i {
        margin-left: 7px;
        font-size: 18px;
    }

    :deep(.offer-image-label) {
        padding: 0;
    }

    :deep(.offer-image-label > .upload_wpr) {
        /* margin-top: 50px; */
        margin-bottom: 0px !important;
    }

    :deep(.offer-image-label > .upload_wpr label) {
        border: 0px;
        border-radius: 0;
        padding: 0;
        min-height: auto;
    }
    :deep(.offer-image-label > .upload_wpr label.blank_img){
        min-height: 170px;
    }

    :deep(.offer-image-label .upload_wpr .info.mt-3) {
        display: none;
    }

    :deep(.offer-image-label .preview_area .upload_wpr .info.mt-3) {
        display: block;
    }

    .upload_image label {
        border: 0;
    }

    .upload_image {
        position: relative;
        overflow: hidden;
    }

    .upload_image h3 {
        padding: 20px 30px;
        margin: 0;
    }
    .upload_image.draft h3{
        opacity: 0.5;
    }
    .upload_image {
        position: relative;
        /* border: 1px solid #e9e9e9; */
        background: #fff;
        border-radius: 8px;
    }

    .product_card {
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        overflow: hidden;
        padding: 0;
        line-height: 0;
        background: #fff;
    }

    .product_card h3 {
        padding: 20px 30px;
        margin: 0;
    }

    .product_card img {
        width: 100%;
        height: 270px;
        object-fit: cover;
    }

    .coach_card {
        /* border: 1px solid #e9e9e9; */
        border-radius: 8px;
        padding: 20px 30px;
        background: #fff;
        position: relative;
        overflow: hidden;
    }

    .coach_card .user_box {
        display: flex;
        align-items: center;
        margin: 25px 0;
    }

    .coach_card .user_box img {
        width: 60px;
        height: 60px;
        margin-right: 15px;
    }

    .coach_card h3 {
        align-items: center;
    }

    .coach_card .user_box .user_info h3 {
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
        color: #121525;
        margin: 0;
    }

    .coach_card .user_box .user_info h5 {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        margin: 5px 0 0 0;
    }
    /* :deep(.coach_card p ){
        margin: 20px 0 10px 0;
    } */
    :deep(.coach_card p p) {
        font-size: 15px !important;
        line-height: 27px !important;
        min-height: 27px;
        font-weight: 300;
        color: #0E101A;
        font-family: 'Inter', sans-serif;
        padding: 0 !important;
    }
    :deep(.cell .coach_card p) {
        font-size: 15px !important;
        line-height: 27px !important;
        min-height: 27px;
    }
    :deep(.cell .coach_card p:empty) {
        min-height: 27px;
    }


    .button_list li {
        margin-top: 20px;
        position: relative;
        overflow: hidden;
    }

    .button_list .button_left {
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
        color: #fff;
        padding: 15px 30px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        width: auto;
    }

    .button_list .button_center {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: #fff;
        padding: 15px 30px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        width: 100%;
        font-family: 'Inter', sans-serif;
    }

    .button_list .button_right {
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
        color: #fff;
        padding: 15px 30px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        width: auto;
        float: right;
    }

    .button_list li>button {
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
        color: #fff;
        padding: 15px 30px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        width: 100%;
    }

    .button_list li button img {
        filter: brightness(0) invert(1);
    }

    /* .button_list.dotted_button li button{
            border: 1.5px dashed #fff;
        } */
    .download_list{
        background: transparent;
        padding: 15px 0 0 0;
        display: flex;
        flex-direction: column;
        gap: 0;
        border: 0;
        border-radius: 0;
    }

    .download_list li {
        background: #f5f5f5;
        border: 0;
        box-shadow: none;
        display: flex;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 15px;
    }

    .download_list li img {
        width: 80px;
        height: auto;
    }

    .tab .download_list li img,
    .cell .download_list li img {
        width: 50px;
    }

    .download_list li h5 {
        padding: 10px 15px;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .download_list li h5 label{
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        padding-left: 5px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .tab .download_list li h5,
    .cell .download_list li h5 {
        padding: 5px 10px;
        font-size: 12px;
        line-height: 15px;
    }

    .download_list li span {
        margin-left: auto;
        width: 50px;
        height: auto;
        border-left: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .tab .download_list li span,
    .cell .download_list li span {
        width: 35px;
        font-size: 14px;
    }

    .faq_list {
        list-style-type: none;
    }

    .faq_list li {
        border: 1px solid #eaeaea;
        padding: 15px 30px;
        border-radius: 6px;
        margin-bottom: 15px;
        background: #fff;
    }

    .faq_list li h5 {
        font-size: 16px;
        line-height: 23px;
        color: #121525;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .faq_list li h5 i {
        transform: rotate(0deg);
        transition: all 0.3s ease-in-out;
    }

    .faq_list li p {
        font-size: 13px;
        line-height: 19px;
        color: #5a5a5a;
        font-weight: 400;
        padding-top: 15px;
        display: none;
    }

    .faq_list li.show p {
        display: block;
    }

    .faq_list li.show h5 i {
        transform: rotate(-180deg);
    }

    :deep(.optin_list) {
        display: flex;
        flex-wrap: wrap;
        background: #fff;
        padding: 10px 0;
        border-radius: 6px;
    }

    :deep(.optin_list li) {
        flex: 0 0 33.333%;
        padding: 10px 15px;
        display: flex;
    }

    :deep(.optin_list li label) {
        cursor: pointer;
    }
    :deep(.optin_list li label.checkbox p){
        padding-left: 8px;
    }

    .buttonList li {
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        margin-bottom: 15px;
    }

    .buttonList li h3 {
        padding: 10px 20px;
        font-size: 16px;
        line-height: 22px;
        color: #121525;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }

    .buttonList li h3 i {
        transform: rotate(0);
        transition: all 0.3s ease-in-out;
    }

    .buttonList li .setting_wpr {
        padding: 10px 20px;
        display: none;
        border-top: 1px solid #e9e9e9;
    }

    .buttonList li.active .setting_wpr {
        display: block;
    }

    .buttonList li.active h3 i {
        transform: rotate(-180deg);
    }

    /* :deep(.dashboard_content p.para p) {
        font-size: 14px !important;
        line-height: 22px;
        color: #585858;
        font-weight: 400;
        font-family: 'Inter', sans-serif;
    } */
    :deep(.dashboard_content p.para){
        padding: 0;
    }
    /* :deep(.cell .dashboard_content p.para p){
        font-size: 16px !important;
        line-height: 25px;
    } */
    .video_wpr {
        position: relative;
    }

    .video_wpr img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 6px;
    }

    .video_wpr .action {
        display: flex;
        align-items: center;
        position: absolute;
        right: 30px;
        top: 20px;
    }

    .video_wpr .action img {
        width: 27px;
        height: 27px;
        filter: brightness(0) invert(0.5);
    }

    .area_body .dashboard_content {
        max-width: 1170px;
        margin: 0 auto;
        padding: 50px 0 20px;
        justify-content: center;
    }

    .area_body.tab .dashboard_content {
        padding: 40px 0 20px;
    }

    .area_body.cell .dashboard_content {
        padding: 20px 0 10px;
    }

    .area_body.cell .dashboard_content .left_side,
    .area_body.cell .dashboard_content .right_side {
        padding: 15px 15px;
    }

    .step_action {
        position: absolute;
        background: #fff;
        right: -150px;
        top: 20px;
        padding: 6px 15px;
        border-radius: 16px 0 0 16px;
        border: 1px solid #eaeaea;
        border-right: 0;
        /* box-shadow: 0 1px 15px rgba(0, 0, 0, 0.1); */
        display: flex;
        align-items: center;
        transition: all 0.5s ease-in-out;
        z-index: 1;
    }

    .step_action label.switch_option {
        transform: scale(0.8);
    }

    .button_list.dotted_button li .step_action {
        top: 10px;
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.3);
    }

    .upload_image .step_action {
        top: 15px;
    }

    .button_list.dotted_button li .step_action .edit {
        color: #fff;
    }

    .section_item:hover .step_action,
    .coach_card:hover .step_action,
    .upload_image:hover .step_action {
        right: 0;
    }

    .coach_card.draft>*:not(.step_action),
    .section_item.draft>*:not(.step_action),
    :deep(.upload_image.draft > label > .upload_wpr *),
    .button_list li.draft>button {
        opacity: 0.5;
    }

    :deep(.upload_image.draft > label > .upload_wpr .uploaded_img) {
        opacity: 0.5;
    }

    .tab .client_card .card_body h1 {
        font-size: 40px;
        line-height: 50px;
    }

    .tab .client_card .card_body h4 {
        font-size: 16px;
        line-height: 21px;
    }

    .cell .client_card .card_body h1 {
        font-size: 20px;
        line-height: 25px;
    }

    .cell .client_card .card_body h4 {
        font-size: 13px;
        line-height: 16px;
    }

    button.header_setting {
        position: relative;
        margin-right: 0;
        margin-left: 15px;
        /* padding: 6px 15px; */
        /* border-radius: 16px 0 0 16px; */
        /* background: #fff; */
        color: #9f9e9e;
        font-size: 14px;
        cursor: pointer;
        /* border: 1px solid #d9d9d9; */
        border-right: 0;
        transition: all 0.3s ease-in-out;
    }

    /* .client_card .card_header:hover button.header_setting {
        margin-right: -30px;
    } */

    .client_card .card_header .user_box {
        margin-left: auto;
    }

    :deep(.button-uploader) {
        position: absolute;
        left: 25px;
        top: 25px;
        background: #fff;
        z-index: 3;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        transition: all 0.3s ease-in-out;
    }
    :deep(.card_body:hover .button-uploader){
        left: 25px;
    }

    :deep(.button-uploader .image_setting) {
        height: 41px;
        width: 41px;
        position: relative;
    }

    :deep(.button-uploader .upload_btn) {
        left: 0;
        top: 0;
    }

    :deep(.upload_btn .select_img) {
        height: 15px !important;
    }

    :deep(.video_wpr) {
        position: relative;
        line-height: 0;
        padding-bottom: 56.25%; /* 16:9 */
        /* padding-top: 25px; */
        /* margin-bottom: 15px; */
    }

    :deep(.video_wpr iframe) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }

    .video_wpr .action {
        z-index: +9999999;
    }

    /* .card_header {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    } */

    .text-center {
        justify-content: center;
    }

    :deep(.card_body.cvr_image.draft) {
        opacity: 0.5;
    }

    .coach_card .step_action.step_top {
        right: 20px;
        top: 20px;
        background: transparent;
    }

    .text_align_left {
        justify-content: left;
        text-align-last: left;
    }

    .text_align_right {
        justify-content: right;
        text-align-last: right;
    }

    .text_align_center {
        justify-content: center;
        text-align-last: center;
    }

    .logo_img {
        max-width: 100%;
        height: auto;
        max-height: 30px;
    }
    /* :deep(.area_body.tab .dashboard_content p.para p){
        font-size: 13px !important;
        line-height: 20px;
    }
    :deep(.area_body.cell .dashboard_content p.para p){
        font-size: 12px !important;
        line-height: 20px;
    } */
    .section_item.downloads{
        margin: 0;
    }
    .step_card .step_action, .upload_image .step_action, .dotted_item .step_action, .coach_card .step_action,
    .downloads .step_action, .video_wpr .step_action, .footer-content .step_action{
        background: transparent;
        right: 0;
        top: 5px;
        border: 0;
        padding: 6px 10px;
    }
    .footer-content .step_action .edit {
        filter: invert(100%);
    }
    @media(max-width: 1199px){
        .dashboard_content{
            padding: 50px 0;
        }
        .dashboard_content .left_side{
            padding: 10px 15px;
        }
        .dashboard_content .right_side{
            padding: 10px 15px;
        }
    }
    @media(max-width: 991px){
        /* :deep(.coach_card p p) {
            font-size: 13px !important;
            line-height: 20px !important;
        } */
        .coach_card .user_box img {
            width: 45px!important;
            height: 45px!important;
        }
        .coach_card .user_box .user_info h5 {
            font-size: 11px;
            line-height: 14px;
        }
        .dashboard_content .coach_card h3 {
            font-size: 18px!important;
            line-height: 25px!important;
        }
    }
    @media(max-width: 767px){
        .dashboard_content .left_side, .dashboard_content .right_side{
            flex: 0 0 100%;
            padding: 0;
        }
        .upload_image h3, .coach_card, .border-item, .dotted_item{
            padding: 15px 20px;
        }
        .upload_image img{
            width: 100%;
        }
        .section_item.downloads{
            max-width: 100%;
        }
    }
</style>
